<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-card-body>
              <!-- Biaya -->
              <b-form-group label="Biaya" label-for="cost">
                <vue-numeric
                  id="cost"
                  v-model="model.cost"
                  @keypress.native="numbersOnly"
                  class="form-control"
                  placeholder="Masukkan Biaya"
                  currency="Rp"
                  separator="."
                ></vue-numeric>
              </b-form-group>

              <!-- Uraian -->
              <b-form-group label="Uraian" label-for="note">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Uraian"
                  rules="required"
                >
                  <b-form-input
                    id="note"
                    v-model="model.note"
                    placeholder="Masukkan uraian..."
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-card-body>
            <b-card-footer align="right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                class="mr-1"
                @click="goBack"
              >
                Kembali
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                type="submit"
              >
                Simpan
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BForm,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  name: 'SppdDonationForm',
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BForm,
    BFormGroup,
    BFormInput,
  },
  directives: { Ripple },
  data() {
    const { sppdId, sppdDonationId } = this.$route.params
    const { action: metaAction } = this.$route.meta

    return {
      sppdId,
      sppdDonationId,
      metaAction,
      config: {
        api: '/sppd/donation',
        redirect: `/manajemen-sppd/${sppdId}/detail`,
      },
      model: {
        cost: 0,
        note: '',
      },
    }
  },
  mounted() {
    if (this.metaAction === 'edit') {
      this.getDonation()
    }
  },
  methods: {
    numbersOnly(event) {
      const evt = event || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    save() {
      const { note, cost } = this.model
      if (this.metaAction === 'store') {
        axios
          .post(`${this.config.api}`, {
            sppd_id: this.sppdId,
            note,
            total_cost: cost,
          })
          .then(() => {
            this.goBack({
              query: {
                event: 'success',
                title: 'Berhasil',
                text: 'Biaya sumbangan pindah berhasil ditambahkan',
              },
            })
          })
          .catch(e => {
            console.error(e.message)
          })
      } else {
        axios
          .put(`${this.config.api}/${this.sppdDonationId}`, {
            sppd_id: this.sppdId,
            note,
            total_cost: cost,
          })
          .then(() => {
            this.goBack({
              query: {
                event: 'success',
                title: 'Berhasil',
                text: 'Biaya sumbangan pindah berhasil diubah',
              },
            })
          })
          .catch(e => {
            console.error(e.message)
          })
      }
    },
    goBack({ query }) {
      this.$router.push({
        path: this.config.redirect,
        query: { ...query, activeTab: 'pindah' },
      })
    },
    getDonation() {
      axios.get(`${this.config.api}/${this.sppdDonationId}`).then(res => {
        const { total_cost: cost, note } = res.data.data

        this.model = { cost, note }
      })
    },
  },
}
</script>

<style></style>
